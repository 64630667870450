/*eslint-disable*/
import { veterainary, website } from "@/axios";

export default {
  getRequiredDocuments() {
    return veterainary().get("acceptance-docs?required_for=doctors");
  },
  uploadDotorDocument(payload) {
    return website().post("upload", payload);
  },
  addDoctor(payload) {
    return veterainary().post("clinics/add-doctor", payload);
  },
  updateDoctor(payload) {
    return veterainary().Patch("clinics/add-doctor", payload);
  },
};
